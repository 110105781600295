<template>
  <div>
    <general-table
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <template #cell(content)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div style="max-width:60rem;">
              <span style="white-space: pre-wrap;">
                <!-- Show truncated or full content based on the toggle -->
                {{ isExpanded(data.item.id) ? data.item.content : truncateContent(data.item.content) }}
              </span>
              <!-- Show "Show more/less" button only if the content is longer than 15 words -->
              <span
                v-if="data.item.content.split(' ').length > 15"
                class="toggle-content"
                @click="toggleContent(data.item.id)"
              >
                {{ isExpanded(data.item.id) ? 'show less' : 'show more' }}
              </span>
            </div>
          </div>
        </slot>
      </template>

      <template #cell(imageUrl)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div
            v-if="data.item.imageUrl"
            class="py-1"
          >
            <b-avatar
              :src="data.item.imageUrl"
              size="5rem"
            />
          </div>
          <div
            v-else
            class="py-1"
          >
            <feather-icon
              icon="BookIcon"
              size="60"
            />
          </div>
        </slot>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'posts',
      addType: 'Add Post',
      addComponentName: 'add-post',
      editComponent: 'edit-post',
      viewContent: false,
      expandedIds: [],
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'imageUrl', label: 'image' },
        { key: 'title', label: 'title' },
        { key: 'content', label: 'content' },
        { key: 'category', label: 'category' },
        { key: 'actions' },
      ],
    }
  },

  computed: {
    apiUrl() {
      let base = 'posts?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      return base.slice(0, -1)
    },
  },
  // mounted() {
  //   console.log('Count in mounted:', this.role)
  // },

  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
    truncateContent(content) {
      const words = content.split(' ')
      if (words.length > 15) {
        return `${words.slice(0, 15).join(' ')}...`
      }
      return content
    },
    toggleContent(id) {
      // Toggle the expanded state for the given ID
      if (this.expandedIds.includes(id)) {
        this.expandedIds = this.expandedIds.filter(expandedId => expandedId !== id)
      } else {
        this.expandedIds.push(id)
      }
    },
    isExpanded(id) {
      // Check if the given ID is in the expanded set
      return this.expandedIds.includes(id)
    },
  },
}
</script>

<style>
.toggle-content {
  color:#3599db;
  cursor: pointer;
}
</style>
